


















































import Vue from 'vue';
import Component from 'vue-class-component';
import { RejectResponse } from 'common-modules/src/store/moduleStore';
import CommonFormElement, { SelectOption } from 'common-modules/src/components/CommonFormElement.vue';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';
import BackBar from '@/components/BackBar.vue';

@Component({
  components: {
    BackBar,
    CommonFormElement,
    JwlSubmitRow,
  },
})
export default class JwlFacilitatorEdit extends Vue {
  $refs!: {
    submitForm: HTMLFormElement;
  }

  fetching = true;
  newFacilitatorType = 'online';
  formHasErrors = false;
  formSubmitStatus = 0;
  error: RejectResponse | null = null;
  facilitator: any|null = null;
  courses: SelectOption[] = [];
  learningGroups: SelectOption[] = [];
  isLeadFacilitator = false;

  changeFacilitatorType (newValue: Record<string, string>): void {
    if (newValue) {
      this.newFacilitatorType = newValue.id;
    }
  }

  changeIsLeadFacilitator (newValue: boolean): void {
    this.isLeadFacilitator = newValue;
  }

  submitForm (): void {
    this.formHasErrors = false;
    const { submitForm } = this.$refs;

    if (this.facilitator && submitForm.reportValidity() && this.formSubmitStatus !== 1) {
      const formData = new FormData(submitForm);
      formData.set('id', this.facilitator.id.toString());
      if (this.isLeadFacilitator) {
        formData.set('lead-facilitator-able', 'true');
      }
      this.formSubmitStatus = 1;
      this.$store.dispatch('postData', {
        url: 'facilitator',
        formData,
      }).then(() => {
        this.formSubmitStatus = 2;

        setTimeout(() => {
          this.formSubmitStatus = 0;
        }, 2000);
      }).catch(() => {
        this.formSubmitStatus = -1;

        setTimeout(() => {
          this.formSubmitStatus = 0;
        }, 2000);
      });
    } else {
      this.formHasErrors = true;
      this.formSubmitStatus = 3;
    }
  }

  get courseQualifications (): number[] {
    const coursesOptions: number[] = [];

    this.facilitator.courseQualifications.forEach((course: any) => {
      coursesOptions.push(course.id.toString());
    });

    return coursesOptions;
  }

  get facilitatorTypeOption (): SelectOption[] {
    return [
      { id: 'online', label: this.$t('facilitatorManagement.typeOnline') },
      { id: 'onsite', label: this.$t('facilitatorManagement.typeOnsite') },
    ];
  }

  get fullName (): string {
    return `${this.facilitator?.firstName} ${this.facilitator?.lastName}`;
  }

  mounted (): void {
    this.$store.dispatch('getData', `facilitator/detail/${this.$route.params.facilitator}`)
      .then((data) => {
        this.facilitator = data.user;
        this.isLeadFacilitator = data.user.isLeadFacilitator;
        this.courses = data.courses;
        this.learningGroups = data.learningGroups;
        if (this.facilitator.learningGroups.length > 0) {
          this.newFacilitatorType = 'onsite';
        }
        this.fetching = false;
      })
      .catch((e) => {
        this.error = e;
      });
  }
}
